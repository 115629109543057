export enum RentType {
    HOUR = 0,
    DAY = 1,
    PERIOD = 4,
}

export const RentTypeLabels: Record<RentType, string> = {
    [RentType.HOUR]: '時租',
    [RentType.DAY]: '日租',
    [RentType.PERIOD]: '時段租',
};

export const RentTypeUnits: Record<RentType, string> = {
    [RentType.HOUR]: '小時',
    [RentType.DAY]: '天',
    [RentType.PERIOD]: '時段',
};
