import {parseNumber, parseRentUnit} from "~/utils/parseTool";
import holidays from "~/config/holidays";
import {RentType, RentTypeUnits} from "~/utils/enums/RentType";

type RentGroups = Record<RentType, number>;

/// {'0':價錢, '1':價錢, '4':價錢} (沒有就是沒有該類型)
export function getTypePrice(rentGroups) {
    const overallCheapestPrices = getCheapestPrices(rentGroups)

    const cheapestPrice = {}
    if (overallCheapestPrices.cheapestHourlyPrice) {
        cheapestPrice[RentType.HOUR] = overallCheapestPrices.cheapestHourlyPrice
    }
    if (overallCheapestPrices.cheapestDailyPrice) {
        cheapestPrice[RentType.DAY] = overallCheapestPrices.cheapestDailyPrice
    }
    if (overallCheapestPrices.cheapestPeriodPrice) {
        cheapestPrice[RentType.PERIOD] = overallCheapestPrices.cheapestPeriodPrice
    }

    return cheapestPrice
}

/// 所有專案 最小價錢 (null 就是沒有這個類型)
/// {
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }
export function getCheapestPrices(rentGroups) {
    // 遍歷所有的 rentGroup, 組出每組專案每個類型的最小價格
    const prices = getAllProjectCheapestPrices(rentGroups)

    // 篩選出所有專案、類型中的最小價格
    const overallCheapestPrices = prices.reduce((acc, current) => {
        return {
            cheapestHourlyPrice: Math.min(acc.cheapestHourlyPrice, current.cheapestHourlyPrice || Infinity),
            cheapestDailyPrice: Math.min(acc.cheapestDailyPrice, current.cheapestDailyPrice || Infinity),
            cheapestPeriodPrice: Math.min(acc.cheapestPeriodPrice, current.cheapestPeriodPrice || Infinity)
        }
    }, {
        cheapestHourlyPrice: Infinity,
        cheapestDailyPrice: Infinity,
        cheapestPeriodPrice: Infinity
    })

    // 如果有值是 Infinity，則設為 null
    overallCheapestPrices.cheapestHourlyPrice = overallCheapestPrices.cheapestHourlyPrice === Infinity ? null : overallCheapestPrices.cheapestHourlyPrice
    overallCheapestPrices.cheapestDailyPrice = overallCheapestPrices.cheapestDailyPrice === Infinity ? null : overallCheapestPrices.cheapestDailyPrice
    overallCheapestPrices.cheapestPeriodPrice = overallCheapestPrices.cheapestPeriodPrice === Infinity ? null : overallCheapestPrices.cheapestPeriodPrice

    return overallCheapestPrices
}

/// 各專案 最小價錢 (null 就是沒有這個類型)
/// [{
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }, .....]
export function getAllProjectCheapestPrices(rentGroups) {
    return rentGroups.map(group => getProjectCheapestPrices(group))
}

/// 單專案 最小價格
/// {
//     "cheapestHourlyPrice": 100,
//     "cheapestDailyPrice": 200,
//     "cheapestPeriodPrice": null
// }
export function getProjectCheapestPrices(group) {
    const rentTypes = group.rent_types

    // 初始化最便宜價格
    let cheapestHourlyPrice = Infinity
    let cheapestDailyPrice = Infinity
    let cheapestPeriodPrice = Infinity

    // 遍歷每個租金類型
    for (const [type, details] of Object.entries(rentTypes)) {
        let allPrices = []

        // 獲取每個類型內的所有價格
        if (details['week_days']) {
            Object.values(details['week_days']).forEach(dayPrices => {
                dayPrices.forEach(item => {
                    allPrices.push(parseFloat(item.price))
                })
            })
        }

        if (details['holiday']) {
            details['holiday'].forEach(item => {
                allPrices.push(parseFloat(item.price))
            })
        }

        if (details['dates']) {
            Object.values(details['dates']).forEach(datePrices => {
                datePrices.forEach(item => {
                    allPrices.push(parseFloat(item.price))
                })
            })
        }

        // 找到該類型中的最小價格
        const minPrice = allPrices.length > 0 ? Math.min(...allPrices) : Infinity

        // 判斷類型並更新對應的最小價格
        if (type === "0") {
            cheapestHourlyPrice = Math.min(cheapestHourlyPrice, minPrice)
        } else if (type === "1") {
            cheapestDailyPrice = Math.min(cheapestDailyPrice, minPrice)
        } else if (type === "4") {
            cheapestPeriodPrice = Math.min(cheapestPeriodPrice, minPrice)
        }
    }

    return {
        cheapestHourlyPrice: cheapestHourlyPrice === Infinity ? null : cheapestHourlyPrice,
        cheapestDailyPrice: cheapestDailyPrice === Infinity ? null : cheapestDailyPrice,
        cheapestPeriodPrice: cheapestPeriodPrice === Infinity ? null : cheapestPeriodPrice
    }
}

/// 拉出時租最小租期
export function getMinShortestRentalDuration(rentGroups) {
    const shortestRentalDurations = rentGroups.map(group => {
        const rentType = group.rent_types["0"]
        if (!rentType) return 0

        // 收集所有 shortest_rental_duration 值
        let durations = []

        // 遍歷 week_days
        for (const day in rentType.week_days) {
            rentType.week_days[day].forEach(entry => {
                durations.push(entry.shortest_rental_duration);
            })
        }

        // 遍歷 holiday
        rentType.holiday.forEach(entry => {
            durations.push(entry.shortest_rental_duration)
        })

        // 遍歷 dates
        for (const date in rentType.dates) {
            rentType.dates[date].forEach(entry => {
                durations.push(entry.shortest_rental_duration)
            })
        }

        // 找出最小值
        return durations.length > 0 ? Math.min(...durations) : 0
    })
    return Math.min(...shortestRentalDurations)
}

/// 最便宜價錢 for list
export function parseFormatCheapestRentPrice(rentGroups, rentType) {
    // ["1",123] 第 [1] 才是價錢
    const price = getCheapestRentObject(rentGroups, rentType)[1]
    return 'NT$' + parseNumber(price)
}

/// 最便宜的類型 for list
export function parseFormatCheapestRentType(rentGroups) {
    const type = getCheapestRentObject(rentGroups)[0]
    return parseRentUnit(type)
}

/// 取得所有方案便宜 [類型, 價格] for list
// ['0', 價錢] or ['1', 價錢] or ['4', 價錢]
export function getCheapestRentObject(rentGroups: RentGroups, rentType?: RentType): [RentType, number] {
    /// 補類型參數
    if (rentType === RentType.DAY) {
        const typePrice = getTypePrice(rentGroups)[rentType]
        return [rentType, typePrice]
    }

    // 將 Object.entries 的鍵顯式轉型為 RentType
    const entries = Object.entries(getTypePrice(rentGroups)).map(
        ([key, value]) => [Number(key) as RentType, value] as [RentType, number]
    );

    // 找出最便宜的類型與價格
    return entries.reduce((minEntry, currentEntry) =>
        currentEntry[1] < minEntry[1] ? currentEntry : minEntry
    );
    /*return Object.entries(getTypePrice(rentGroups))
        .reduce((minEntry, currentEntry) => {
        return currentEntry[1] < minEntry[1] ? currentEntry : minEntry
    });*/
}

/// 選日期後，拉取價格設定參數
/// date : yyyy/MM/dd
//[
//     {
//         "start": "12:00",
//         "end": "23:59",
//         "price": "20",
//         "shortest_rental_duration": 1.0
//     }, ...
// ]
export function getRentSettingByDate(rent_type, date) {
    /// 1.
    // "dates":{
    //     "2024-12-31":[
    //         {
    //             "start": "12:00",
    //             "end": "23:59",
    //             "price": "20",
    //             "shortest_rental_duration": 1.0
    //         }
    //     ]
    // }
    /// 2.
    // "holiday":[{ ... }],
    /// 3.
    // "week_days":{
    //     "1": [{ ... }], "2": [{ ... }], ...
    // }

    /// 先判斷特殊日
    if (rent_type.dates) {
        // 將 date 格式化成 "YYYY-MM-DD"
        const formattedDate = date.replace(/\//g, "-")
        if (Object.keys(rent_type.dates).includes(formattedDate)) {
            return rent_type.dates[formattedDate]
        }
    }

    /// 判斷假日
    if (rent_type.holiday && rent_type.holiday.length > 0) {
        // 將 date 格式化成 "YYYYMMDD"
        const formattedDate = date.replace(/\//g, "")
        if (holidays.some(holiday => holiday.date === formattedDate)) {
            return rent_type.holiday
        }
    }

    /// 判斷平日
    if (rent_type.week_days) {
        return rent_type.week_days[getWeekDay(new Date(date))]
    }

}

/// 星期日 0, 星期一 1...
export function getWeekDay(date) {
    const day = date.getDay();
    return day === 7 ? 0 : day;
}

/// 拉不到，日期就擋住不給選
export function isDateLocked(rent_type, date) {
    return getRentSettingByDateForCalendar(rent_type, date) === undefined
}

/// 總結：判斷特殊日、平日，有就可選
export function getRentSettingByDateForCalendar(rent_type, date) {
    /*
    * 日期開放不開放判斷邏輯
    *
    * 開放判斷優先級 依序判斷 特殊日 > 開放日價格。*假日價格不涉及開放不開放
    *
    * 若確認開放，再依 特定日期 > 假日價格 > 開放日價格 的優先順序決定開放時間與價格。
    *
    * 假設我六日 `沒有設定` 開放結束時間，可是假日價格有設定，那這樣六日還是不開
    * 假設六日 `有設定` 開放結束時間，假日價格有設定，那這樣六日的假日價格會 overwrite 開放日價格
    *
    * */

    /// 先判斷特殊日
    if (rent_type.dates) {
        // 將 date 格式化成 "YYYY-MM-DD"
        const formattedDate = date.replace(/\//g, "-")
        if (Object.keys(rent_type.dates).includes(formattedDate)) {
            return rent_type.dates[formattedDate]
        }
    }

    /// 判斷平日
    if (rent_type.week_days) {
        return rent_type.week_days[getWeekDay(new Date(date))]
    }
}


export function tuneCrossDay(rentSetting) {
    /// 跨天改 24+
    rentSetting.forEach(setting => {
        /// 是否跨天
        const [startHour, startMinute] = setting.start.split(":").map(Number)
        const [endHour, endMinute] = setting.end.split(":").map(Number)
        const isCrossDay = (startHour === endHour && startMinute >= endMinute) || startHour > endHour
        if (isCrossDay) {
            const [endHour, endMinute] = setting.end.split(":").map(Number)
            setting.end = `${endHour + 24}:${endMinute.toString().padStart(2, "0")}`
        }
    })
}
